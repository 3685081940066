<template>
  <div class="login-block">
    <loading v-if="cargando" />
    <div class="row">
      <!--<div class="col-md-6 offset-md-3 text-center">
        <img src="/img/icons/pegaso-icon.png" alt="">
      </div>-->
      <div class="container container-login">
        <div class="row">
          <div class="col-md-12 login-sec">
            <h2 class="text-center">
              <img
                src="/img/logo--fondo-fronteraenergy.png"
                alt=""
                style="width:200px"
              />
            </h2>
            <form class="login-form" @submit.prevent="login">
              <span class="badge badge-warning" v-show="$v.form.$invalid">
                Por favor ingrese un correo para actualizar su contraseña
              </span>
              <div class="input-group mb-3">
                <input
                  type="email"
                  class="form-control"
                  v-model="form.email"
                  placeholder="Email"
                  :class="$v.form.email.$invalid ? 'is-invalid' : 'is-valid'"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-envelope"></span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <router-link to="/Login" class="btn btn-default">
                  Volver
                </router-link>
                <button
                  type="button"
                  class="btn btn-login float-right"
                  v-show="!$v.form.$invalid"
                  @click="sendMail()"
                >
                  Enviar Email
                </button>
              </div>
            </form>
            <div class="row mt-3">
              <div class="col-md-12 text-center">
                <img src="/img/logo-sos.png" alt="" style="width: 80px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.login-block {
  background: -webkit-linear-gradient(
    to bottom,
    #201747,
    #001871
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #201747,
    #001871
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  float: left;
  width: 100%;
  padding: 50px 0;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-login {
  background: #fff;
  border-radius: 10px;
  box-shadow: 15px 20px 0px rgba(0, 0, 0, 0.1);
}
.login-sec {
  padding: 50px 30px 20px 30px;
  position: relative;
}
.login-sec .copy-text {
  position: absolute;
  width: 80%;
  bottom: 20px;
  font-size: 13px;
  text-align: center;
}
.login-sec .copy-text i {
  color: #001871;
}
.login-sec .copy-text a {
  color: #ff1c79;
}
.login-sec h2 {
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 30px;
  color: #001871;
}
.login-sec h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #001871;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
}
</style>
<script>
import axios from "axios";
import { required, email } from "vuelidate/lib/validators";
import Loading from "../components/Loading";
export default {
  components: {
    Loading,
  },
  data: () => ({
    form: {
      email: "",
    },
    cargando: false,
  }),
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    sendMail() {
      this.cargando = true;
      let data = this.form;
      axios
        .post("/api/password/email", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.cargando = false;
          this.$swal({
            icon: "success",
            title: "Ok...",
            text: res.data.success,
          });
          return this.$router.replace("/Login");
        })
        .catch((error) => {
          this.cargando = false;
          this.$swal({
            icon: "success",
            title: "Ok...",
            text: "Las instrucciones de recuperación fueron enviadas a su correo electrónico.",
          });
          return this.$router.replace("/Login");
        });
    },
  },
};
</script>
